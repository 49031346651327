import React from 'react';

import classes from './Modal.module.scss';
import Auxiliary from '../../../hoc/Auxiliary';
import Backdrop from '../Backdrop/Backdrop';

const modal = (props) => (
  <Auxiliary>
    <Backdrop show={props.show} clicked={props.modalClosed} />
    <div
      className={classes.Modal}
      style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1' : '0',
      }}
    >
      {props.children}
    </div>
  </Auxiliary>
);
export default React.memo(
  modal,
  (prevProps, nextProps) => nextProps.show === prevProps.show
    && nextProps.children === prevProps.children,
);
